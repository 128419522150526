// import React, { useState } from "react";
// import axios from "axios";

// const App = () => {
//   const [query, setQuery] = useState("");
//   const [results, setResults] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);

//   const token = "797019:67406d47064c2"; // جایگزین کنید با توکن خودتان

//   const handleSearch = async () => {
//     if (!query.trim()) return;
//     setLoading(true);
//     setError(null);
//     setResults(null);

//     try {
//       const response = await axios.get(
//         `https://one-api.ir/dictionary/`,
//         {
//           params: {
//             token: token,
//             action: "moein",
//             q: query,
//           },
//         }
//       );
//       setResults(response.data);
//     } catch (err) {
//       setError("خطایی در دریافت اطلاعات رخ داده است.");
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
//       <h1>لغت‌نامه معین</h1>
//       <input
//         type="text"
//         placeholder="عبارت مورد نظر را وارد کنید..."
//         value={query}
//         onChange={(e) => setQuery(e.target.value)}
//         style={{ padding: "10px", width: "300px", fontSize: "16px" }}
//       />
//       <button
//         onClick={handleSearch}
//         style={{
//           padding: "10px 20px",
//           marginLeft: "10px",
//           fontSize: "16px",
//           cursor: "pointer",
//         }}
//       >
//         جستجو
//       </button>

//       {loading && <p>در حال جستجو...</p>}

//       {error && <p style={{ color: "red" }}>{error}</p>}

//       {results && (
//         <div style={{ marginTop: "20px" }}>
//           <h2>نتیجه:</h2>
//           {results.result ? (
//             <div>
//               <p><strong>عبارت:</strong> {results.result.word}</p>
//               <p><strong>تعریف:</strong> {results.result.definition}</p>
//             </div>
//           ) : (
//             <p>نتیجه‌ای یافت نشد.</p>
//           )}
//         </div>
//       )}
//     </div>
//   );
// };

// export default App;



import React, { useState } from "react";
import axios from "axios";
import "./App.css";

const App = () => {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const token = "797019:67406d47064c2";

  const handleSearch = async () => {
    if (!query.trim()) return;
    setLoading(true);
    setError(null);
    setResults([]);

    try {
      const response = await axios.get(
        `https://one-api.ir/dictionary/`,
        {
          params: {
            token: token,
            action: "moein",
            q: query,
          },
        }
      );
      console.log('response',response)
      response?.data?.status == 200 ?
      setResults(response?.data?.result) 
      :setError("موردی یافت نشد.");
    } catch (err) {
      setError("خطایی در دریافت اطلاعات رخ داده است.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="app">
      <h1 className="title">لغت‌ نامه معین</h1>
      <div className="search-container">
        <input
          type="text"
          placeholder="عبارت مورد نظر را وارد کنید..."
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          className="search-input"
        />
        <button onClick={handleSearch} className="search-button">
          جستجو
        </button>
      </div>

      {loading && <p className="loading">در حال جستجو...</p>}

      {error && <p className="error">{error}</p>}

      {results.length > 0 && (
        <div className="results-container">
          <h2 className="results-title">نتایج:</h2>
          <ul className="results-list">
            {results?.map((item) => (
              <li key={item?.id} className="result-item">
                <p>
                  <strong>کلمه:</strong> {item.word}
                </p>
                <p>
                  <strong>معنی:</strong> {item.meaning}
                </p>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default App;

